import React from "react"

import Layout from "../components/Layout"
import SEO from "@/components/SEO"
import { StaticImage } from "gatsby-plugin-image"

type PageProps = {
  data: any
}

const ShareYourViews: React.FC<PageProps> = ({ data }) => (
  <Layout
    sectionOne={
      <>
        <StaticImage
          src="../../static/images/2021/02/recognising-your-support.jpg"
          className="absolute inset-0 -z-[1]"
          alt="Share Your Views"
        />
        <div className="container text-white pt-96 pb-4 flex flex-col items-center text-center">
          <h1 className="pb-2 md:pb-8 responsive-heading uppercase">
            Share Your{" "}<span className="text-primary">Views</span>
          </h1>
          <div className="pt-8 flex justify-center">
            <img src="/images/down.png" alt="Down arrow" />
          </div>
        </div>
      </>
    }
  >
    <SEO title="Share Your Views" />
    <div className="container py-14">
      <div className="flex justify-center">
        <div className="max-w-[700px]">
          <h3 className="mb-4 text-3xl uppercase">
            Every gift to the Royal Belfast Academical Institution will benefit <span className="text-primary">
              future
              generations of pupils
            </span>, and we are keen to recognise your support.
          </h3>
          <h3 className="mb-4 text-3xl uppercase">
            Please review the acknowledgement opportunities below. We also
            respect the privacy of any donor who wishes to remain anonymous.
          </h3>

          <img
            src="/images/2023/03/gift-table-mar23.jpg"
            className="w-full max-w-sm mx-auto mt-6 lg:mt-8"
          />
        </div>
      </div>
    </div>
  </Layout>
)

export default ShareYourViews
